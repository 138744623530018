.header {
  background-color:#294b75;
  margin-bottom: 10px;
  height: 80px;
}

.header__container {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.header__link {
  text-decoration: none;

  &--name {
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
  }
}

.header__logged-state {
  color: #fff;
}