.layout__content {
  min-height: calc(100vh - 170px);
}

.stardust-casino {
  background-color: #161822;
  .header {
    background-color: #000000;
  }
  .footer {
    background-color: #000000;
  }
}