.join {
    &__wrapper{
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 2rem;
      h1 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
      }
    
      ol {
        list-style-type: decimal;
        li {
          margin-bottom: 5px;
        }
      }
    }
    &__code {
        white-space: pre-wrap;
        display: block;
        background: rgba(0,0,0,0.2);
        max-width: 300px;
        width: 100%;
        font-size: 12px;
        padding: 10px;
      }
  }