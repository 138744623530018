.footer {
  background-color:#294b75;
  height: 60px;
  margin-top: 20px;
}

.footer__container {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
}

.footer__body--text {
  color: #fff;
}