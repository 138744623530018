.debug-modal {
  &__version {
    font-size: 12px;
    margin: 5px 0;
  }

  fieldset {
    margin-bottom: 10px;

    textArea {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #000000;
    h2 {
      font-size: 20px;
      font-weight: bold;
    }
  }
  
  &__close-btn {
    background: none;
    border: 1px solid #000000;
    border-radius: 3px;
    font-weight: bold;
    font-size: 16px;
  }

  label {
    display: block;
    font-weight: bold;

    + label {
      margin-top: 10px;
    }

    span {
      display: block;
      margin-bottom: 5px;
    }
  }

  .radio-container {
    display: flex;
    margin-bottom: 10px;
  }
  .radio-result {
    margin-bottom: 10px;
  }
}

.base-url__input {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}

.base-url__btn {
 margin-left: 3px;
}